<template>
  <div id="inside">
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-2 pl-1">
            <button
              class="justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 text-primary"
              @click="home()"
            >
              <i class="fa fa-chevron-left fa-fw pull-left text-info"></i>
              Voltar
            </button>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row" v-if="billsInfo && !loadBoletos">
          <div class="col-12 mb-1 pl-1">
            <h5 class="d-flex align-items-center">
              <!-- {{ isEmitirFatura ? "Emitir fatura" : "Boletos" }} -->
              Parcelas do acordo
            </h5>
          </div>
          <HeaderOptions />
        </div>
      </div>
    </div>
    <ProgressLoading v-bind:loading="loadBoletos"></ProgressLoading>

    <div class="container" v-if="pixInfo">
      <div class="row">
        <div
          class="d-inline-flex flex-wrap flex-column col-acao mt-0 pt-0 col-lg-9"
        >
          <div class="card m-1">
            <div class="card-body px-4">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div v-bind:class="[largura < 500 ? '' : 'flex-card m-5']">
                    <div
                      v-bind:class="[
                        largura < 500
                          ? 'col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2 flex-align-center'
                          : '',
                      ]"
                    >
                      <qrcode-vue
                        :value="pixInfo.pixCopyPaste"
                        :size="150"
                        level="H"
                      />
                    </div>
                    <div class="my-auto">
                      <p class="text-info text-center mx-4">
                        Acesse seu App de pagamentos e faça a leitura do QR Code
                        ao lado para efetuar o pagamento de forma segura e
                        rápida.
                      </p>
                    </div>
                  </div>
                  <div style="color: red; font-size: 12px">
                    ATENÇÃO! Evite fraudes: Antes de efetuar o PIX para
                    pagamento do seu acordo PORTOBANK, verifique o NOME do
                    beneficiário e CNPJ.
                  </div>
                  <div class="col-xs-8 col-12 col-lg-12 px-1 mt-4">
                    <p class="text-info mb-1">
                      Código de pagamento PIX:<a
                        class="btn btn-link btn-sm float-right pl-1 pr-3"
                        style="cursor: pointer;"
                        v-if="pixInfo.pixCopyPaste"
                        @click="copiarLinhaDigitavel(pixInfo.pixCopyPaste)"
                        >Copiar</a
                      >
                    </p>
                    <p @click="copyCodePix" class="codebar">
                      {{ pixInfo.pixCopyPaste }}
                    </p>
                  </div>
                  <div>
                    <button
                      @click="getBillInfo()"
                      class="btn mr-1 mb-1 btn-link text-left"
                    >
                      Exibir boletos
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="!billsInfo && !loadBoletos && !pixInfo">
      <h4 class="mb-1 text-danger d-flex flex-row align-items-center">
        Não foi possível apresentar dados da emissão de fatura.
      </h4>
    </div>
    <div
      class="container"
      v-if="billsInfo && !loadBoletos && !fromEmitirFatura && !fromProposals"
    >
      <div class="row">
        <div class="container">
          <div class="card m-1">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div
                    class="d-flex"
                    style="justify-content: space-between"
                    v-if="!loading && paidInstallments.length > 0"
                  >
                    <h5 class="text-bold mt-2">Parcelas pagas</h5>
                    <a
                      class="nav-link dropdown-toggle"
                      href="#boleto"
                      id="userDropdown"
                      role="button"
                      @click="toggleDropdownPaidInstallments"
                      aria-haspopup="true"
                      aria-expanded="dropdownOpen"
                    >
                      <i v-if="!dropdownOpenPaidInstallments" class="fa fa-angle-down fa-lg"></i>
                      <i v-if="dropdownOpenPaidInstallments" class="fa fa-angle-up fa-lg"></i>
                    </a>
                  </div>
                  <hr style="margin-top: 0" />
                  <div
                    class="accordion mb-4"
                    id="boleto"
                    v-show="dropdownOpenPaidInstallments"
                  >
                    <div
                      class="card m-0 px-0 py-0"
                      v-for="(installment, key, index) in paidInstallments"
                      :key="`${key}-${index}`"
                    >
                      <div
                        class="card-header mb-2"
                        id="head2"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row p-3">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body" v-if="isEmitirFatura">
                              {{ key + 1 + "/" + (key + 1) }}
                            </h6>
                            <h6 class="text-body" v-if="isFromProposals">
                              {{
                                installment.installmentNumber +
                                "/" +
                                String(billsInfo.installmentNumber)
                              }}
                            </h6>
                            <h6 class="text-body" v-if="isFromAgreements">
                              {{
                                parseInt(installment.installmentNumber) +
                                1 +
                                "/" +
                                String(agreementInfo.installmentNumber)
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(installment.paymentValue, 10)
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="">
                              {{
                                formatData(
                                  installment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="installment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="installment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion mb-2"
                    v-if="currentInstallment && currentInstallment.pixCopyPaste"
                  >
                    <div class="col-12 text-center">
                      <h5 class="text-bold">Parcela em aberto</h5>
                    </div>
                    <div
                      class="container mt-4"
                      style="
                        background-color: rgb(245, 245, 245);
                        border-radius: 5px;
                      "
                    >
                      <div
                        class="card-header mb-2"
                        id="head1"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row mt-2">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body">
                              {{
                                parseInt(currentInstallment.installmentNumber) +
                                1 +
                                "/" +
                                String(agreementInfo.installmentNumber)
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(
                                    currentInstallment.paymentValue,
                                    10
                                  )
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="" style="color: black">
                              {{
                                formatData(
                                  currentInstallment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="currentInstallment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="currentInstallment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                      <hr style="border-top: 1px solid #151515" />
                      <div class="" id="divPix">
                        <div class="col-12 text-center">
                          <h5 class="text-bold">PIX</h5>
                        </div>
                        <div v-bind:class="[largura < 500 ? '' : 'flex-card m-5']">
                          <div
                            v-bind:class="[
                              largura < 500
                                ? 'col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2 flex-align-center'
                                : '',
                            ]"
                          >
                            <qrcode-vue
                              :value="currentInstallment.pixCopyPaste"
                              :size="150"
                              level="H"
                              style="background-color: #fff; padding: 10px"
                            />
                          </div>
                          <div class="my-auto">
                            <p class="text-center" v-if="largura > 500">
                              Acesse seu App de pagamentos e faça a leitura do
                              QR Code ao lado para efetuar o pagamento de forma
                              segura e rápida.
                            </p>
                            <p class="text-center" v-if="largura < 500">
                              Acesse seu App de pagamentos e faça a leitura do
                              QR Code acima para efetuar o pagamento de forma
                              segura e rápida.
                            </p>
                          </div>
                        </div>
                        <div style="color: red; font-size: 12px">
                          ATENÇÃO! Evite fraudes: Antes de efetuar o PIX para
                          pagamento do seu acordo PORTOBANK, verifique o NOME do
                          beneficiário e CNPJ.
                        </div>
                        <div class="mt-3">
                          <p class="mb-0">Código de pagamento PIX:</p>
                        </div>
                        <div
                          class="col-xs-8 col-12 col-lg-12 px-1 mt-2"
                          style="background-color: #d9d9d9; border-radius: 5px"
                        >
                          <div
                            class="d-flex"
                            style="align-items: center;"
                            @click="
                              copiarCodigoPix(currentInstallment.pixCopyPaste)
                            "
                          >
                            <p
                              class="codebar text-start"
                              style="background-color: #d9d9d9"
                            >
                              {{ currentInstallment.pixCopyPaste }}
                            </p>
                            <i class="fa fa-clone mr-2" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div
                          class="text-center"
                          @click="
                            copiarCodigoPix(currentInstallment.pixCopyPaste)
                          "
                        >
                          <p style="color: black; cursor: pointer;">Clique para copiar</p>
                        </div>
                        <div v-if="!boletoGerado">
                          <button
                            :disabled="loadingBoleto"
                            @click="getBillInfo()"
                            class="btn btn-primary mr-2 mb-4 p-2"
                            style="width: 100%; border-radius: 20px"
                          >
                            GERAR BOLETO
                          </button>
                        </div>
                      </div>

                      <div
                        class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                        style=""
                        v-if="
                          loadingBoleto &&
                          contentInstallment === currentInstallment
                        "
                      >
                        <ProgressLoading v-bind:loading="loadingBoleto" />
                      </div>

                      <div
                        v-bind:class="[
                          contentInstallment.digitableLine
                            ? 'collapse show'
                            : 'collapse',
                        ]"
                        aria-labelledby="heading1"
                        style=""
                        v-if="contentInstallment.digitableLine"
                      >
                        <h6 class="border-bottom pb-3 mb-0" v-if="pix">
                          Boleto
                        </h6>
                        <div class="card-body px-4">
                          <div
                            class="row"
                            v-if="
                              contentInstallment.digitableLine ||
                              contentInstallment.status !== 'Liquidada'
                            "
                          >
                            <div class="col-xs-8 col-12 col-lg-12 px-1">
                              <p class="text-info mb-1">Linha digitável:</p>
                              <a
                                class="text-primary btn-link btn-sm float-right mt-2 pl-1 pr-3"
                                v-if="contentInstallment.digitableLine"
                                @click="
                                  copiarLinhaDigitavel(
                                    contentInstallment.digitableLine
                                  )
                                "
                                style="cursor: pointer;"
                                >Copiar</a
                              >
                              <p
                                class="codebar"
                                style="background-color: rgb(217, 217, 217)"
                              >
                                {{ contentInstallment.digitableLine }}
                              </p>
                            </div>
                            <div
                              class="col-12 d-flex col-lg-12 flex-row px-0 mt-2 justify-content-around"
                            >
                              <button
                                class="text-primary btn-link mr-1 mb-1 text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura || loading
                                "
                                @click="downloadBoleto(contentInstallment)"
                              >
                                <i
                                  class="fa fa-download fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i>
                                Baixar boleto
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  (!user.PhoneNumber && !user.MobileNumber)
                                "
                                @click="
                                  sendSms(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-mobile fa-fw fa-lg pull-left mr-1 pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por SMS
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  !user.Email
                                "
                                @click="
                                  sendEmail(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-envelope-o fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por e-mail
                              </button>
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="loading"
                            >
                              <ProgressLoading v-bind:loading="loading" />
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="
                                loadingDownloadEmitirFatura
                              "
                            >
                              <ProgressLoading
                                v-bind:loading="loadingDownloadEmitirFatura"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div
                        v-bind:class="[
                          contentInstallment.digitableLine
                            ? 'collapse show'
                            : 'collapse',
                        ]"
                        aria-labelledby="heading1"
                        style=""
                        v-if="contentInstallment.digitableLine && !currentInstallment.pixCopyPaste"
                      >
                        <h6 class="border-bottom pb-3 mb-0">
                          Boleto
                        </h6>
                        <div class="card-body px-4">
                          <div
                            class="row"
                            v-if="
                              contentInstallment.digitableLine ||
                              contentInstallment.status !== 'Liquidada'
                            "
                          >
                            <div class="col-xs-8 col-12 col-lg-12 px-1">
                              <p class="text-info mb-1">Linha digitável:</p>
                              <a
                                class="text-primary btn-link btn-sm float-right mt-2 pl-1 pr-3"
                                v-if="contentInstallment.digitableLine"
                                style="cursor: pointer;"
                                @click="
                                  copiarLinhaDigitavel(
                                    contentInstallment.digitableLine
                                  )
                                "
                                >Copiar</a
                              >
                              <p
                                class="codebar"
                                style="background-color: rgb(217, 217, 217)"
                              >
                                {{ contentInstallment.digitableLine }}
                              </p>
                            </div>
                            <div
                              class="col-12 d-flex col-lg-12 flex-row px-0 mt-2 justify-content-around"
                            >
                              <button
                                class="text-primary btn-link mr-1 mb-1 text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura || loading
                                "
                                @click="downloadBoleto(contentInstallment)"
                              >
                                <i
                                  class="fa fa-download fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i>
                                Baixar boleto
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  (!user.PhoneNumber && !user.MobileNumber)
                                "
                                @click="
                                  sendSms(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-mobile fa-fw fa-lg pull-left mr-1 pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por SMS
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  !user.Email
                                "
                                @click="
                                  sendEmail(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-envelope-o fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por e-mail
                              </button>
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="loading"
                            >
                              <ProgressLoading v-bind:loading="loading" />
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="
                                loadingDownloadEmitirFatura
                              "
                            >
                              <ProgressLoading
                                v-bind:loading="loadingDownloadEmitirFatura"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                  

                  <hr />
                  <div
                    class="d-flex"
                    style="
                      justify-content: space-between;
                      align-items: baseline;
                    "
                    v-if="!loading && openInstallments.length > 0"
                  >
                    <h5 class="text-bold mt-2">Mais parcelas em aberto</h5>
                    <a
                      class="nav-link dropdown-toggle"
                      href="#openParcels"
                      id="userDropdownOpen"
                      role="button"
                      @click="toggleDropdownOpenInstallments"
                      aria-haspopup="true"
                      aria-expanded="dropdownOpen"
                    >
                      <i v-if="!dropdownOpenInstallments" class="fa fa-angle-down fa-lg"></i>
                      <i v-if="dropdownOpenInstallments" class="fa fa-angle-up fa-lg"></i>
                    </a>
                  </div>
                  <div class="accordion" v-show="dropdownOpenInstallments">
                    <div
                      class="card m-0 px-0 py-0 mb-2"
                      v-for="(
                        openInstallment, key1, index1
                      ) in openInstallments"
                      :key="`${key1}-${index1}`"
                    >
                      <div
                        class="card-header"
                        id="head3"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row p-3">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body" v-if="isEmitirFatura">
                              {{ key1 + 1 + "/" + (key1 + 1) }}
                            </h6>
                            <h6 class="text-body" v-if="isFromProposals">
                              {{
                                openInstallment.installmentNumber +
                                "/" +
                                String(billsInfo.installmentNumber)
                              }}
                            </h6>
                            <h6 class="text-body" v-if="isFromAgreements">
                              {{
                                parseInt(openInstallment.installmentNumber) +
                                1 +
                                "/" +
                                String(agreementInfo.installmentNumber)
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(openInstallment.paymentValue, 10)
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="">
                              {{
                                formatData(
                                  openInstallment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="openInstallment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="openInstallment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      v-if="billsInfo && !loadBoletos && fromProposals"
    >
      <div class="row">
        <div class="container">
          <div class="card m-1">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div
                    class="d-flex"
                    style="justify-content: space-between"
                    v-if="!loading && paidInstallments.length > 0"
                  >
                    <h5 class="text-bold mt-2">Parcelas pagas</h5>
                    <a
                      class="nav-link dropdown-toggle"
                      href="#boleto"
                      id="userDropdown"
                      role="button"
                      @click="toggleDropdownPaidInstallments"
                      aria-haspopup="true"
                      aria-expanded="dropdownOpen"
                    >
                      <i v-if="!dropdownOpenPaidInstallments" class="fa fa-angle-down fa-lg"></i>
                      <i v-if="dropdownOpenPaidInstallments" class="fa fa-angle-up fa-lg"></i>
                    </a>
                  </div>
                  <hr style="margin-top: 0" v-if="paidInstallments.length > 0" />
                  <div
                    class="accordion mb-4"
                    id="boleto"
                    v-show="dropdownOpenPaidInstallments"
                  >
                    <div
                      class="card m-0 px-0 py-0"
                      v-for="(installment, key, index) in paidInstallments"
                      :key="`${key}-${index}`"
                    >
                      <div
                        class="card-header mb-2"
                        id="head2"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row p-3">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body">
                              {{
                                installment.installmentNumber +
                                "/" +
                                String(billsInfo.installmentNumber)
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(installment.paymentValue, 10)
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="">
                              {{
                                formatData(
                                  installment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="installment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="installment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion mb-2"
                  >
                    <div class="col-12 text-center">
                      <h5 class="text-bold">Parcela em aberto</h5>
                    </div>
                    <div
                      class="container mt-4"
                      style="
                        background-color: rgb(245, 245, 245);
                        border-radius: 5px;
                      "
                    >
                      <div
                        class="card-header mb-2"
                        id="head1"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row mt-2">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body">
                              {{
                                parseInt(currentInstallment.installmentNumber) +
                                "/" +
                                String(agreementInfo.installmentNumber)
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(
                                    currentInstallment.paymentValue,
                                    10
                                  )
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="" style="color: black">
                              {{
                                formatData(
                                  currentInstallment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="currentInstallment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="currentInstallment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                      <!-- <hr style="border-top: 1px solid #151515" /> -->
                      <div class="" id="divPix" v-if="currentInstallment.pixCopyPaste">
                        <div class="col-12 text-center">
                          <h5 class="text-bold">PIX</h5>
                        </div>
                        <div v-bind:class="[largura < 500 ? '' : 'flex-card m-5']">
                          <div
                            v-bind:class="[
                              largura < 500
                                ? 'col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2 flex-align-center'
                                : '',
                            ]"
                          >
                            <qrcode-vue
                              :value="currentInstallment.pixCopyPaste"
                              :size="150"
                              level="H"
                              style="background-color: #fff; padding: 10px"
                            />
                          </div>
                          <div class="my-auto">
                            <p class="text-center" v-if="largura > 500">
                              Acesse seu App de pagamentos e faça a leitura do
                              QR Code ao lado para efetuar o pagamento de forma
                              segura e rápida.
                            </p>
                            <p class="text-center" v-if="largura < 500">
                              Acesse seu App de pagamentos e faça a leitura do
                              QR Code acima para efetuar o pagamento de forma
                              segura e rápida.
                            </p>
                          </div>
                        </div>
                        <div style="color: red; font-size: 12px">
                          ATENÇÃO! Evite fraudes: Antes de efetuar o PIX para
                          pagamento do seu acordo PORTOBANK, verifique o NOME do
                          beneficiário e CNPJ.
                        </div>
                        <div class="mt-3">
                          <p class="mb-0">Código de pagamento PIX:</p>
                        </div>
                        <div
                          class="col-xs-8 col-12 col-lg-12 px-1 mt-2"
                          style="background-color: #d9d9d9; border-radius: 5px"
                        >
                          <div
                            class="d-flex"
                            style="align-items: center;"
                            @click="
                              copiarCodigoPix(currentInstallment.pixCopyPaste)
                            "
                          >
                            <p
                              class="codebar text-start"
                              style="background-color: #d9d9d9"
                            >
                              {{ currentInstallment.pixCopyPaste }}
                            </p>
                            <i class="fa fa-clone mr-2" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div
                          class="text-center"
                          @click="
                            copiarCodigoPix(currentInstallment.pixCopyPaste)
                          "
                        >
                          <p style="color: black; cursor: pointer;">Clique para copiar</p>
                        </div>
                        <div v-if="!boletoGerado">
                          <button
                            :disabled="loadingBoleto"
                            @click="getBillInfo()"
                            class="btn btn-primary mr-2 mb-4 p-2"
                            style="width: 100%; border-radius: 20px"
                          >
                            GERAR BOLETO
                          </button>
                        </div>
                      </div>

                      <div
                        class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                        style=""
                        v-if="
                          loadingBoleto &&
                          contentInstallment === currentInstallment
                        "
                      >
                        <ProgressLoading v-bind:loading="loadingBoleto" />
                      </div>

                      <div
                        v-bind:class="[
                          contentInstallment.digitableLine
                            ? 'collapse show'
                            : 'collapse',
                        ]"
                        aria-labelledby="heading1"
                        style=""
                        v-if="contentInstallment.digitableLine"
                      >
                        <h6 class="border-bottom pb-3 mb-0">
                          Boleto
                        </h6>
                        <div class="card-body px-4">
                          <div
                            class="row"
                            v-if="
                              contentInstallment.digitableLine ||
                              contentInstallment.status !== 'Liquidada'
                            "
                          >
                            <div class="col-xs-8 col-12 col-lg-12 px-1">
                              <p class="text-info mb-1">Linha digitável:</p>
                              <a
                                class="text-primary btn-link btn-sm float-right mt-2 pl-1 pr-3"
                                v-if="contentInstallment.digitableLine"
                                style="cursor: pointer;"
                                @click="
                                  copiarLinhaDigitavel(
                                    contentInstallment.digitableLine
                                  )
                                "
                                >Copiar</a
                              >
                              <p
                                class="codebar"
                                style="background-color: rgb(217, 217, 217)"
                              >
                                {{ contentInstallment.digitableLine }}
                              </p>
                            </div>
                            <div
                              class="col-12 d-flex col-lg-12 flex-row px-0 mt-2 justify-content-around"
                            >
                              <button
                                class="text-primary btn-link mr-1 mb-1 text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura || loading
                                "
                                @click="downloadBoleto(contentInstallment)"
                              >
                                <i
                                  class="fa fa-download fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i>
                                Baixar boleto
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  (!user.PhoneNumber && !user.MobileNumber)
                                "
                                @click="
                                  sendSms(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-mobile fa-fw fa-lg pull-left mr-1 pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por SMS
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  !user.Email
                                "
                                @click="
                                  sendEmail(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-envelope-o fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por e-mail
                              </button>
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="loading"
                            >
                              <ProgressLoading v-bind:loading="loading" />
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="
                                loadingDownloadEmitirFatura
                              "
                            >
                              <ProgressLoading
                                v-bind:loading="loadingDownloadEmitirFatura"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="d-flex"
                    style="
                      justify-content: space-between;
                      align-items: baseline;
                    "
                    v-if="!loading && openInstallments.length > 0"
                  >
                    <h5 class="text-bold mt-2">Mais parcelas em aberto</h5>
                    <a
                      class="nav-link dropdown-toggle"
                      href="#openParcels"
                      id="userDropdownOpen"
                      role="button"
                      @click="toggleDropdownOpenInstallments"
                      aria-haspopup="true"
                      aria-expanded="dropdownOpen"
                    >
                      <i v-if="!dropdownOpenInstallments" class="fa fa-angle-down fa-lg"></i>
                      <i v-if="dropdownOpenInstallments" class="fa fa-angle-up fa-lg"></i>
                    </a>
                  </div>
                  <div class="accordion" v-show="dropdownOpenInstallments">
                    <div
                      class="card m-0 px-0 py-0 mb-2"
                      v-for="(
                        openInstallment, key1, index1
                      ) in openInstallments"
                      :key="`${key1}-${index1}`"
                    >
                      <div
                        class="card-header"
                        id="head3"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row p-3">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body">
                              {{
                                openInstallment.installmentNumber +
                                "/" +
                                String(billsInfo.installmentNumber)
                              }}
                            </h6>

                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(openInstallment.paymentValue, 10)
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="" style="color: black">
                              {{
                                formatData(
                                  openInstallment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="openInstallment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="openInstallment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="billsInfo && !loadBoletos && fromEmitirFatura">
      <div class="row">
        <div class="container">
          <div class="card m-1">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <!-- v-if="currentInstallment && currentInstallment.pixCopyPaste" -->
                  <div class="accordion mb-2">
                    <div class="col-12 text-center">
                      <h5 class="text-bold">Parcela em aberto</h5>
                    </div>
                    <div
                      class="container mt-4"
                      style="
                        background-color: rgb(245, 245, 245);
                        border-radius: 5px;
                      "
                    >
                      <div
                        class="card-header mb-2"
                        id="head1"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row mt-2">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-0" style="color: black">Parcela</p>
                            <p
                              class="text-body mb-0"
                              style="font-weight: bold; font-size: 12pt"
                            >
                              {{
                                parseInt(currentInstallment.installmentNumber) +
                                "/" +
                                String(billsInfo.installmentNumber)
                              }}
                            </p>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-0" style="color: black">Valor</p>
                            <p
                              class="text-body mb-0"
                              style="font-weight: bold; font-size: 12pt"
                            >
                              {{
                                formatFloatToReal(
                                  parseFloat(
                                    currentInstallment.paymentValue,
                                    10
                                  )
                                )
                              }}
                            </p>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-0" style="color: black">Vencimento</p>
                            <p class="text-body mb-0" style="font-size: 12pt">
                              {{
                                formatData(
                                  currentInstallment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </p>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-0" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="currentInstallment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="currentInstallment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                      <hr style="border-top: 1px solid #151515" v-if="currentInstallment.pixCopyPaste" />
                      <!-- <hr /> -->
                      <div class="" id="divPix" v-if="currentInstallment && currentInstallment.pixCopyPaste">
                        <div class="col-12 text-center">
                          <h5 class="text-bold">PIX</h5>
                        </div>
                        <div v-bind:class="[largura < 500 ? '' : 'flex-card m-5']">
                          <div
                            v-bind:class="[
                              largura < 500
                                ? 'col-xs-8 col-12 d-flex flex-column col-lg-5 pb-3 pt-2 flex-align-center'
                                : '',
                            ]"
                          >
                            <qrcode-vue
                              :value="currentInstallment.pixCopyPaste"
                              :size="150"
                              level="H"
                              style="background-color: #fff; padding: 10px"
                            />
                          </div>
                          <div class="my-auto">
                            <p class="text-center" v-if="largura > 500">
                              Acesse seu App de pagamentos e faça a leitura do
                              QR Code ao lado para efetuar o pagamento de forma
                              segura e rápida.
                            </p>
                            <p class="text-center" v-if="largura < 500">
                              Acesse seu App de pagamentos e faça a leitura do
                              QR Code acima para efetuar o pagamento de forma
                              segura e rápida.
                            </p>
                          </div>
                        </div>
                        <div style="color: red; font-size: 12px">
                          ATENÇÃO! Evite fraudes: Antes de efetuar o PIX para
                          pagamento do seu acordo PORTOBANK, verifique o NOME do
                          beneficiário e CNPJ.
                        </div>
                        <div class="mt-3" style="font-size: 12px">
                          Lembrando que os pagamentos realizados via PIX são
                          baixados até 2 horas. Caso pague via boleto o prazo de
                          baixa é de 2 dias úteis.
                        </div>
                        <div class="mt-3">
                          <p class="mb-0">Código de pagamento PIX:</p>
                        </div>
                        <div
                          class="col-xs-8 col-12 col-lg-12 px-1 mt-2"
                          style="background-color: #d9d9d9; border-radius: 5px"
                        >
                          <div
                            class="d-flex"
                            style="align-items: center;"
                            @click="
                              copiarCodigoPix(currentInstallment.pixCopyPaste)
                            "
                          >
                            <p
                              class="codebar text-start"
                              style="background-color: #d9d9d9"
                            >
                              {{ currentInstallment.pixCopyPaste }}
                            </p>
                            <i class="fa fa-clone mr-2" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div
                          class="text-center"
                          @click="
                            copiarCodigoPix(currentInstallment.pixCopyPaste)
                          "
                        >
                          <p style="color: black; cursor: pointer;">Clique para copiar</p>
                        </div>
                        <div v-if="!boletoGerado">
                          <button
                            v-if="!loadingBoleto"
                            @click="getBillInfo()"
                            class="btn-primary mr-2 mb-4 p-2"
                            style="width: 100%; border-radius: 20px"
                          >
                            GERAR BOLETO
                          </button>
                        </div>
                      </div>

                      <div
                        class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                        style=""
                        v-if="
                          loadingBoleto &&
                          contentInstallment === currentInstallment
                        "
                      >
                        <ProgressLoading v-bind:loading="loadingBoleto" />
                      </div>

                      <div
                        v-bind:class="[
                          contentInstallment.digitableLine
                            ? 'collapse show mt-3'
                            : 'collapse',
                        ]"
                        aria-labelledby="heading1"
                        style=""
                        v-if="contentInstallment.digitableLine"
                      >
                        <h6 class="border-bottom pb-3 mb-0 ml-2">
                          Boleto
                        </h6>
                        <div class="card-body px-4">
                          <div
                            class="row"
                            v-if="
                              contentInstallment.digitableLine ||
                              contentInstallment.status !== 'Liquidada'
                            "
                          >
                            <div class="col-xs-8 col-12 col-lg-12 px-1">
                              <p class="text-info mb-1">Linha digitável:</p>
                              <a
                                class="text-primary btn-link btn-sm float-right mt-2 pl-1 pr-3"
                                v-if="contentInstallment.digitableLine"
                                style="cursor: pointer;"
                                @click="
                                  copiarLinhaDigitavel(
                                    contentInstallment.digitableLine
                                  )
                                "
                                >Copiar</a
                              >
                              <p
                                class="codebar"
                                style="background-color: rgb(217, 217, 217)"
                              >
                                {{ contentInstallment.digitableLine }}
                              </p>
                            </div>
                            <div
                              class="col-12 d-flex col-lg-12 flex-row px-0 mt-2 justify-content-around"
                            >
                              <button
                                class="text-primary btn-link mr-1 mb-1 text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura || loading
                                "
                                @click="downloadBoleto(contentInstallment)"
                              >
                                <i
                                  class="fa fa-download fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i>
                                Baixar boleto
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  (!user.PhoneNumber && !user.MobileNumber)
                                "
                                @click="
                                  sendSms(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-mobile fa-fw fa-lg pull-left mr-1 pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por SMS
                              </button>
                              <button
                                class="text-primary mr-1 mb-1 btn-link text-left"
                                :disabled="
                                  loadingDownloadEmitirFatura ||
                                  loading ||
                                  !user.Email
                                "
                                @click="
                                  sendEmail(contentInstallment.digitableLine)
                                "
                              >
                                <i
                                  class="fa fa-envelope-o fa-fw pull-left pt-1 pb-2 d-none d-sm-block"
                                ></i
                                >Receber por e-mail
                              </button>
                              <!-- <pre>{{ contentInstallment }}</pre>
                              <pre>{{ installment }}</pre> -->
                            </div>
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="loading"
                            >
                              <ProgressLoading v-bind:loading="loading" />
                            </div>
                                <!-- contentInstallment.installmentNumber === installment.installmentNumber -->
                            <div
                              class="col-xs-8 col-12 d-flex flex-column col-lg-12 pb-3 pt-2"
                              style=""
                              v-if="
                                loadingDownloadEmitirFatura
                              "
                            >
                              <ProgressLoading
                                v-bind:loading="loadingDownloadEmitirFatura"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div
                    class="d-flex"
                    style="
                      justify-content: space-between;
                      align-items: baseline;
                    "
                    v-if="!loading && openInstallments.length > 0"
                  >
                    <h5 class="text-bold mt-2">Mais parcelas em aberto</h5>
                    <a
                      class="nav-link dropdown-toggle"
                      href="#openParcels"
                      id="userDropdownOpen"
                      role="button"
                      @click="toggleDropdownOpenInstallments"
                      aria-haspopup="true"
                      aria-expanded="dropdownOpen"
                    >
                      <i v-if="!dropdownOpenInstallments" class="fa fa-angle-down fa-lg"></i>
                      <i v-if="dropdownOpenInstallments" class="fa fa-angle-up fa-lg"></i>
                    </a>
                  </div>
                  <div class="accordion" v-show="dropdownOpenInstallments">
                    <div
                      class="card m-0 px-0 py-0 mb-2"
                      v-for="(
                        openInstallment, key1, index1
                      ) in openInstallments"
                      :key="`${key1}-${index1}`"
                    >
                      <div
                        class="card-header"
                        id="head3"
                        style="background-color: #f5f5f5; border-radius: 5px"
                      >
                        <div class="row p-3">
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Parcela</p>
                            <h6 class="text-body" v-if="isEmitirFatura">
                              {{ key1 + 1 + "/" + (key1 + 1) }}
                            </h6>
                            <h6 class="text-body" v-if="isFromProposals">
                              {{
                                openInstallment.installmentNumber +
                                "/" +
                                String(billsInfo.installmentNumber)
                              }}
                            </h6>
                            <h6 class="text-body" v-if="isFromAgreements">
                              {{
                                parseInt(openInstallment.installmentNumber) +
                                1 +
                                "/" +
                                String(agreementInfo.installmentNumber)
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Valor</p>
                            <h6 class="text-body">
                              {{
                                formatFloatToReal(
                                  parseFloat(openInstallment.paymentValue, 10)
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Vencimento</p>
                            <h6 class="">
                              {{
                                formatData(
                                  openInstallment.dueDate,
                                  "YYYY-MM-DD",
                                  "L"
                                )
                              }}
                            </h6>
                          </div>
                          <div
                            class="d-flex col-lg-12 col-12"
                            style="justify-content: space-between"
                          >
                            <p class="mb-1" style="color: black">Status</p>
                            <h6
                              class="status-badge-open"
                              v-if="openInstallment.status === 'EmAberto'"
                            >
                              Em aberto
                            </h6>
                            <h6
                              class="status-badge-paid"
                              v-if="openInstallment.status === 'Liquidada'"
                            >
                              Pago
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="newEmail" height="auto" style="margin: auto">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="eModalLabel">
            Você não tem nenhum e-mail cadastrado.
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            @click="fecharModal()"
          >
            <span aria-hidden="true">x</span>
          </button>
        </div>

        <label class="m-4">Por favor, informe um novo e-mail.</label>
        <div class="col-12">
          <input
            v-model="newEmail"
            :disabled="loading"
            maxlength="50"
            type="text"
            class="form-control my-3"
            placeholder="E-mail"
            @change="eventFillInputEmailGTM()"
          />
        </div>

        <div class="modal-footer">
          <button
            v-if="!loading"
            class="btn btn-secondary text-white"
            type="button"
            data-dismiss="modal"
            @click="fecharModal('cancelar')"
          >
            Cancelar
          </button>
          <a
            v-if="!loading"
            class="btn btn-primary"
            href="#"
            @click="insertNewEmailAndSend()"
            >Cadastrar</a
          >
          <ProgressLoading v-bind:loading="loading" />
        </div>
      </div>
    </modal>
    <modal name="newPhoneNumber" height="auto" style="margin: auto">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="eModalLabel">
            Você não tem nenhum telefone cadastrado.
          </h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            @click="fecharModalNewPhone()"
          >
            <span aria-hidden="true">x</span>
          </button>
        </div>

        <label class="m-4"
          >Por favor, informe um novo número de telefone.</label
        >
        <div class="col-12">
          <input
            type="tel"
            class="form-control form-control-lg text-center home-form quicks mb-2"
            placeholder="Informe o telefone aqui"
            id="form10"
            v-model="newPhoneNumber"
            v-mask="['(##) # ####-####']"
          />
        </div>

        <div class="modal-footer">
          <button
            v-if="!loading"
            class="btn btn-secondary text-white"
            type="button"
            data-dismiss="modal"
            @click="fecharModalNewPhone()"
          >
            Cancelar
          </button>
          <a
            v-if="!loading"
            class="btn btn-primary"
            href="#"
            @click="insertNewPhoneNumberAndSend()"
            >Cadastrar</a
          >
          <ProgressLoading v-bind:loading="loading" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  formatCurrencieValueBRL as formatValue,
  formatData,
  formatFloatToReal
} from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue";
import { initializeGlobals } from "@/global";
import QrcodeVue from "qrcode.vue";

export default {
  name: "meus-boletos",
  components: {
    ProgressLoading,
    HeaderOptions,
    QrcodeVue,
  },
  data() {
    return {
      loadBoletos: false,
      loadingAgreementsUpdated: false,
      loading: false,
      loadingDownloadEmitirFatura: false,
      hasBills: false,
      isFatura: false,
      contractInfo: null,
      agreementsPst: null,
      agreementPstClosed: null,
      billsInfo: null,
      contract: [],
      agreements: [],
      dadosFatura: [],
      digitableLine: null,
      newEmail: null,
      newPhoneNumber: null,
      agreementsAreUpdated: false,
      agreementsUpdated: [],
      contentInstallment: {},
      installmentsNumber: null,
      isEmitirFatura: false,
      isFromProposals: false,
      isFromAgreements: false,
      isEquals: false,
      pageLocation: null,
      userDataStorage: {},
      url: null,
      protocol: null,
      path: null,
      host: null,
      userAdm: {},
      ipUser: null,
      pixInfo: null,
      largura: null,
      pix: null,
      loadingBoleto: false,
      boletoGerado: false,
      dropdownOpenInstallments: false,
      dropdownOpenPaidInstallments: false,
      paidInstallments: [],
      openInstallments: [],
      currentInstallment: null,
      installment: null,
      fromEmitirFatura: false,
      fromProposals: false,
    };
  },

  mounted() {
    this.$emit("updatePageView", this.pageLocation);
  },
  async created() {
    this.largura = window.screen.width;
    this.updateCustomData();
    this.ipUser = localStorage.getItem("ipUser");
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.url = window.location.href;
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + "//" + this.host + this.path;
    this.fromEmitirFatura = this.$route.params.fromEmitirFatura;
    this.fromProposals = this.$route.params.fromProposals;
    
    this.buildAndSetUserData(
      this.userDataStorage.cpfCnpj,
      this.userDataStorage.id,
      this.pageLocation,
      "meus-boletos"
    );

    // eslint-disable-next-line
    // debugger;

    this.largura = window.screen.width;

    if (this.fromEmitirFatura) { 
      this.isEmitirFatura = true;
      try {
        this.loadBoletos = true;
        this.agreementInfo = this.$route.params.agreementInfo;

        const result = await this.ActionEmitirFaturaPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          contract: this.agreementInfo.contract,
          tag: localStorage.getItem("tag"),
          phoneNumber:
            this.user.PhoneNumber != null
              ? this.user.PhoneNumber
              : this.user.MobileNumber,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          hasEmail:
            this.user.Email !== null && this.user.Email !== "" ? true : false,
        });

        if (!result) {
          this.flashMessage.show({
            time: 3000,
            status: "error",
            title: "Error",
            message: "Não foi possível emitir a fatura",
          });
          this.buildAndSetUserData(
            this.userDataStorage.cpfCnpj,
            this.userDataStorage.id,
            this.protocol + "//" + this.host + "/dashboard",
            "Dashboard"
          );
          this.updateCustomData();
          this.$router.push({
            name: "dashboard",
          });
        } else {
          this.billsInfo = this.buildBillsInfoFromEmitirFatura(result);
          this.currentInstallment = this.billsInfo.installments[0];

          this.contentInstallment = this.billsInfo.installments[0];
        }
        this.loadBoletos = false;
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível emitir a fatura",
        });
        this.buildAndSetUserData(
          this.userDataStorage.cpfCnpj,
          this.userDataStorage.id,
          this.protocol + "//" + this.host + "/dashboard",
          "Dashboard"
        );
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadBoletos = false;
      }
    } else if (this.$route.params.fromPst) {
      try {
        this.billsInfo = this.buildBillsInfoFromPst(
          this.$route.params.agreementInfo
        );
        // this.contentInstallment = this.billsInfo.installments[0];
        return;
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível emitir a fatura",
        });
        this.buildAndSetUserData(
          this.userDataStorage.cpfCnpj,
          this.userDataStorage.id,
          this.protocol + "//" + this.host + "/dashboard",
          "Dashboard"
        );
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      } finally {
        this.loadBoletos = false;
      }
    } else if (this.fromProposals) {
      
      this.agreementInfo = this.$route.params.agreementInfo;
      this.billsInfo = this.buildBillsInfoFromParcFat(
        this.$route.params.agreementInfo
      );

      this.paidInstallments = this.billsInfo.installments.filter(
          (installment) => installment.status === "Liquidada"
        );

      const openInstallments = this.billsInfo.installments.filter(
        (installment) => installment.status === "EmAberto"
      );

      this.currentInstallment = openInstallments.length
        ? openInstallments[0]
        : null;

      this.openInstallments = openInstallments.slice(1);

      this.contentInstallment = this.billsInfo.installments[0];
      this.isFromProposals = true;
    } else if (this.$route.params.fromAgreements) {
      this.isFromAgreements = true;
      this.loadBoletos = true;

      this.agreementInfo = this.$route.params.agreementInfo;


      try {
        await this.getBills();

        // const responseFromPix = await this.ActionPixPortoSeguro({
        // clientCode: this.agreementInfo.clientCode,
        // credor: this.agreementInfo.credor,
        // installmentCode: this.agreementInfo.installmentCode,
        // dueDate: this.agreementInfo.dueDate,
        // tag: localStorage.getItem('tag'),
        // UserID: this.userAdm ? this.userAdm.UserID : null,
        // UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        // OS:navigator.platform,
        // IP: this.ipUser ? this.ipUser : '',
        // });

        // this.pixInfo = responseFromPix;
      } catch {
        // await this.getBills();
      } finally {
        this.loadBoletos = false;
      }
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  methods: {
    formatValue,
    formatData,
    formatFloatToReal,

    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    async getBills() {
      try {
        const responseFromAgreementDetails =
          await this.ActionBoletosLinhaDigitavelPortoSeguro({
            clientCode: this.agreementInfo.clientCode,
            credor: this.agreementInfo.credor,
            installmentCode: this.agreementInfo.installmentCode,
            tag: localStorage.getItem("tag"),
            UserID: this.userAdm ? this.userAdm.UserID : null,
            UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
            OS: navigator.platform,
            IP: this.ipUser ? this.ipUser : "",
            hasEmail:
              this.user.Email !== null && this.user.Email !== "" ? true : false,
          });

        this.billsInfo = this.buildBillsInfoFromAgreements(
          responseFromAgreementDetails
        );

        this.paidInstallments = this.billsInfo.installments.filter(
          (installment) => installment.status === "Liquidada"
        );

        const openInstallments = this.billsInfo.installments.filter(
          (installment) => installment.status === "EmAberto"
        );

        this.currentInstallment = openInstallments.length
          ? openInstallments[0]
          : null;
        this.openInstallments = openInstallments.slice(1);

        this.contentInstallment = this.currentInstallment;

        const pix = this.billsInfo.installments.find(
          (inst) => inst.pixCopyPaste
        );
        if (pix) this.pix = pix.pixCopyPaste;
      } catch (err) {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível exibir os boletos do acordo",
        });
        this.buildAndSetUserData(
          this.userDataStorage.cpfCnpj,
          this.userDataStorage.id,
          this.protocol + "//" + this.host + "/dashboard",
          "Dashboard"
        );
        this.updateCustomData();
        this.$router.push({
          name: "dashboard",
        });
      }
    },

    async getBillInfo() {
      try {
        this.loadingBoleto = true;

        this.contentInstallment = this.currentInstallment;

        this.agreementInfo = this.fromEmitirFatura ? this.billsInfo : this.agreementInfo;

        const responseFromBillInfo = await this.ActionBoletoInfoPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          installmentCode: this.agreementInfo.installmentCode,
          installmentDueDate: this.contentInstallment.dueDate,
          boletoDueDate: this.agreementInfo.dueDate,
          dueDate: this.agreementInfo.dueDate,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
        });

        const index = this.billsInfo.installments.findIndex(
          (installment) =>
            installment.installmentNumber ===
            this.currentInstallment.installmentNumber
        );

        this.installment = this.billsInfo.installments[index];
        const newInstallment = {
          ...this.installment,
          digitableLine: responseFromBillInfo.digitableLine,
        };

        this.billsInfo.installments[index] = newInstallment;
        this.billsInfo.installments = [...this.billsInfo.installments];
        this.contentInstallment = newInstallment;

        this.boletoGerado = true;
        this.loadingBoleto = false;
      } catch (err) {
        this.loadingBoleto = false;

        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Error",
          message: "Não foi possível exibir os boletos do acordo",
        });
      }
    },

    expandAndClose(installment) {
      var isEmpyt = this.isObjectEmpty(this.contentInstallment);

      if (!isEmpyt && this.contentInstallment === installment) {
        this.contentInstallment = {};
      } else {
        this.contentInstallment = installment;
      }
    },

    buildBillsInfoFromAgreements(agreementDetails) {
      const installmentNumber = agreementDetails.installments.length;
      return { ...this.agreementInfo, ...agreementDetails, installmentNumber };
    },

    buildBillsInfoFromParcFat(agreementInfo) {
      return {
        ...agreementInfo,
        installmentNumber: parseInt(agreementInfo.installmentNumber),
      };
    },

    buildBillsInfoFromPst(agreementInfo) {
      return {
        ...agreementInfo,
        installmentNumber: parseInt(agreementInfo.installmentNumber) + 1,
      };
    },

    buildBillsInfoFromEmitirFatura(result) {
      return {
        ...this.agreementInfo,
        ...result,
        installmentNumber: 1,
      };
    },

    home() {
      this.eventClickToBackGTM();
      this.buildAndSetUserData(
        this.userDataStorage.cpfCnpj,
        this.userDataStorage.id,
        this.protocol + "//" + this.host + "/dashboard",
        "Dashboard"
      );
      this.updateCustomData();

      this.$router.push({
        name: "dashboard",
      });
    },

    ...mapActions("meusContratos", [
      "ActionEmitirFaturaPortoSeguro",
      "ActionBoletoPortoSeguro",
      "ActionBoletosLinhaDigitavelPortoSeguro",
      "ActionPixPortoSeguro",
      "ActionBoletoInfoPortoSeguro",
    ]),
    ...mapActions("meusBoletos", ["ActionSendEmailOrSmsText"]),

    async sendSms(LinhaDigitavel) {
      // eslint-disable-next-line
    // debugger;
      this.eventInteractElementsGTM("receber-por-sms");
      this.loading = true;
      if (
        this.user.PhoneNumber === "" ||
        (this.user.PhoneNumber === null && this.user.MobileNumber === "") ||
        this.user.MobileNumber === null
      ) {
        this.loading = false;
        this.linhaDigitavel = LinhaDigitavel;
        this.abrirModalNewPhone();

        return;
      }

      this.agreementInfo['installmentDueDate'] = this.currentInstallment.dueDate;
      this.agreementInfo['dueDate'] = this.currentInstallment.dueDate;
      try {
        var result = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel,
          Email: "",
          PhoneNumber:
            this.user.PhoneNumber != null
              ? this.user.PhoneNumber
              : this.user.MobileNumber,
          type: "sms",
          httpOwnerId: this.ownerId,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          contract: this.billsInfo.contract,
          hasEmail:
            this.user.Email !== null && this.user.Email !== "" ? true : false,
          agreementInfo: this.agreementInfo,
        });

        if (result.data.code !== 200) {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "Error",
            message: result.data.message,
          });
          this.loading = false;
          return;
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por sms com sucesso",
          });
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },

    async sendEmail(LinhaDigitavel) {
      this.eventInteractElementsGTM("receber-por-email");
      this.loading = true;
      if (this.user.Email !== "" || this.user.Email !== null) {
        this.loading = false;
        this.linhaDigitavel = LinhaDigitavel;
        this.eventModalViewGTM();
        this.abrirModal();
        return;
      }

      try {
        var resp = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel,
          Email: this.user.Email,
          PhoneNumber: "",
          type: "email",
          httpOwnerId: this.ownerId,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          contract: this.billsInfo.contract,
          hasEmail:
            this.user.Email !== null && this.user.Email !== "" ? true : false,
          agreementInfo: this.agreementInfo,
        });

        if (resp.data.code !== 200) {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "Error",
            message: "Informe um e-mail!",
          });
          this.loading = false;
          return;
        } else {
          this.loading = false;
          this.flashMessage.show({
            time: 3000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por e-mail com sucesso",
          });
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    async insertNewPhoneNumberAndSend() {
      this.loading = true;

      if (this.newPhoneNumber === "" || this.newPhoneNumber === null) {
        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: "Informe um telefone!",
        });
        this.loading = false;
        return;
      }

      try {
        var resp = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel: this.linhaDigitavel,
          Email: "",
          PhoneNumber: this.newPhoneNumber
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace(" ", "")
            .replace("-", ""),
          type: "sms",
          httpOwnerId: this.ownerId,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          contract: this.billsInfo.contract,
          hasEmail:
            this.user.Email !== null && this.user.Email !== "" ? true : false,
          agreementInfo: this.agreementInfo,
        });

        if (resp.data.code !== 200) {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "Error",
            message: "Não foi possível fazer o envio por sms",
          });
          this.loading = false;
          return;
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por sms com sucesso",
          });
          this.loading = false;
          this.fecharModalNewPhone();
        }
      } catch {
        this.loading = false;
      }
    },

    async insertNewEmailAndSend() {
      this.eventClickToCadastrarOrCancelEmailGTM("cadastrar");
      this.loading = true;

      if (this.newEmail === "" || this.newEmail === null) {
        const pageName = this.$options.name;
        const eventCategory = `portal-de-negociacao:porto:${pageName}`;
        const eventAction = "cadastrar-email:alert";
        const eventLabel = "modal:Não foi possível fazer o envio por e-mail";
        const eventCode = 401;
        const eventErrorService = "";
        const eventAlertService = "Informe um e-mail!";
        const eventAlert = "self-service";
        const eventAlertType = "erro";
        const eventData = {
          event: "alert",
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: eventLabel,
          alert_code: eventCode,
          error_service: eventErrorService,
          alert_service_message: eventAlertService,
          alert_event: eventAlert,
          alert_type: eventAlertType,
        };

        window.dataLayer.push({ event: "alert", ...eventData });

        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Error",
          message: "Informe um e-mail!",
        });
        this.loading = false;
        return;
      }

      var email = this.validateEmail(this.newEmail);
      if (!email) {
        const pageName = this.$options.name;
        const eventCategory = `portal-de-negociacao:porto:${pageName}`;
        const eventAction = "cadastrar-email:alert";
        const eventLabel = "modal:Não foi possível fazer o envio por e-mail";
        const eventCode = 401;
        const eventErrorService = "";
        const eventAlertService = "Informe um e-mail válido!";
        const eventAlert = "self-service";
        const eventAlertType = "erro";
        const eventData = {
          event: "alert",
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: eventLabel,
          alert_code: eventCode,
          error_service: eventErrorService,
          alert_service_message: eventAlertService,
          alert_event: eventAlert,
          alert_type: eventAlertType,
        };

        window.dataLayer.push({ event: "alert", ...eventData });

        this.flashMessage.show({
          timeout: 1000,
          status: "error",
          title: "Ocorreu um erro",
          message: "Informe um email válido!",
        });
        this.loading = false;
        return;
      }

      try {
        var resp = await this.ActionSendEmailOrSmsText({
          LinhaDigitavel: this.linhaDigitavel,
          Email: this.newEmail,
          PhoneNumber: "",
          type: "email",
          httpOwnerId: this.ownerId,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          contract: this.billsInfo.contract,
          hasEmail:
            this.user.Email !== null && this.user.Email !== "" ? true : false,
          agreementInfo: this.agreementInfo,
        });

        if (resp.data.code !== 200) {
          this.flashMessage.show({
            timeout: 1000,
            status: "error",
            title: "Error",
            message: "Não foi possível fazer o envio por e-mail",
          });
          this.eventCallbackCadastrarFailureGTM(resp);
          this.loading = false;
          return;
        } else {
          this.flashMessage.show({
            timeout: 1000,
            blockClass: "custom-block-class",
            status: "success",
            title: "Sucesso",
            message: "Linha digitável enviado por e-mail com sucesso",
          });
          this.loading = false;
          this.eventCallbackSuccessCadastrarGTM(resp);
          this.fecharModal();
        }
      } catch {
        this.loading = false;
      }
    },

    async downloadBoleto(installment) {
      this.eventInteractElementsGTM("baixar-boleto");
      this.loadingDownloadEmitirFatura = true;
      this.contentInstallment = installment;

      // Se estiver vencido, passa D + 1
      const installmentDueDate = installment.dueDate;
      const today = new Date();
      const flagDate = today > new Date(installmentDueDate);

      try {
        let result = await this.ActionBoletoPortoSeguro({
          clientCode: this.billsInfo.clientCode,
          credor: this.billsInfo.credor,
          installmentCode: this.billsInfo.installmentCode,
          installmentDueDate,
          boletoDueDate: flagDate
            ? today.toJSON().split("T")[0]
            : installmentDueDate,
          tag: localStorage.getItem("tag"),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : "",
        });

        const linkSource = `data:application/pdf;base64,${result.base64}`;
        const downloadLink = document.createElement("a");
        const fileName = "boleto.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.flashMessage.show({
          time: 3000,
          status: "success",
          title: "Sucesso",
          blockClass: "custom-block-class",
          message: "Download do boleto realizado com sucesso",
        });
      } catch {
        this.flashMessage.show({
          time: 3000,
          status: "error",
          title: "Erro",
          blockClass: "custom-block-class",
          message:
            "Ocorreu um erro ao tentar fazer o download do boleto, tente novamente mais tarde",
        });
      } finally {
        this.loadingDownloadEmitirFatura = false;
      }
    },

    fecharModal(action) {
      if (action) {
        this.eventClickToCadastrarOrCancelEmailGTM(action);
      }
      this.$modal.hide("newEmail");
    },
    abrirModal() {
      this.$modal.show("newEmail");
    },
    fecharModalNewPhone() {
      this.$modal.hide("newPhoneNumber");
    },
    abrirModalNewPhone() {
      this.$modal.show("newPhoneNumber");
    },

    async copiarLinhaDigitavel(linhaDigitavel) {
      this.eventInteractElementsGTM("copiar-linha-digitavel");
      const elem = document.createElement("textarea");
      elem.value = linhaDigitavel;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);

      this.flashMessage.show({
        timeout: 1000,
        status: "success",
        title: "Sucesso",
        blockClass: "custom-block-class",
        message: "Linha digitável foi copiada para o seu clipboard",
      });
    },

    async copiarCodigoPix(pixCode) {
      this.eventInteractElementsGTM("copiar-linha-digitavel");
      const elem = document.createElement("textarea");
      elem.value = pixCode;
      document.body.appendChild(elem);
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);

      this.flashMessage.show({
        timeout: 1000,
        status: "success",
        title: "Sucesso",
        blockClass: "custom-block-class",
        message: "Código copiado para o seu clipboard",
      });
    },

    async gerarLinhaDigitavel(value) {
      var result = await this.ActionGerarLinhaDigitavelPortoSeguro({
        clientCode: value.clientCode,
        credor: value.credor,
        installmentCode: value.installmentCode,
        tag: localStorage.getItem("tag"),
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        OS: navigator.platform,
      });

      if (result) {
        this.hasBills = false;
        this.agreementsAreUpdated = true;
        this.agreementsUpdated = result;
      }
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
        cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
        id: id, // Substitua pelo ID real
        page_location: pageLocation,
        pageName: pageName,
      };
      localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickToBackGTM() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "click:button:voltar";

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
        product_identify: this.agreementInfo.contract,
        product_category: this.agreementInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.agreementInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventInteractElementsGTM(action) {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = `click:button:${action}`;

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "",
        product_identify: this.agreementInfo.contract,
        product_category: this.agreementInfo.product,
        product_user_profile: "titular",
        brand: "porto",
        product: this.agreementInfo.product,
        vertical: "bank",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventModalViewGTM() {
      this.pageName =
        localStorage.getItem("currentPageName") === null
          ? "dashboard"
          : localStorage.getItem("currentPageName");
      const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
      const eventAction = "dialog:visualizar";

      const eventData = {
        event: "dialog",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "modal:voce-nao-tem-nenhum-email-cadastrado",
      };

      window.dataLayer.push({ event: "dialog", ...eventData });
    },

    eventFillInputEmailGTM() {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "preencher:input:email";
      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "modal:voce-nao-tem-nenhum-email-cadastrado",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventClickToCadastrarOrCancelEmailGTM(action) {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = `click:button:${action}`;

      const eventData = {
        event: "select_content",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "modal:voce-nao-tem-nenhum-email-cadastrado",
      };

      window.dataLayer.push({ event: "select_content", ...eventData });
    },

    eventCallbackSuccessCadastrarGTM(callback) {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "cadastrar-email:sucesso";

      const eventData = {
        event: "self_service",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: "modal:Cadastro feito com sucesso /" + callback.data.message,
        service_protocol: "",
        service_detail: "Email cadastrado e envio da linha digitável",
        service_type: "solicitacao",
      };

      window.dataLayer.push({ event: "self_service", ...eventData });
    },

    eventCallbackCadastrarFailureGTM(error) {
      const pageName = this.$options.name;
      const eventCategory = `portal-de-negociacao:porto:${pageName}`;
      const eventAction = "cadastrar-email:alert";
      const eventLabel = "modal:Não foi possível fazer o envio por e-mail";
      const eventCode = error.status;
      const eventErrorService = "";
      const eventAlertService = error.data.message;
      const eventAlert = "self-service";
      const eventAlertType = "erro";
      const eventData = {
        event: "alert",
        ev_category: eventCategory,
        ev_action: eventAction,
        ev_label: eventLabel,
        alert_code: eventCode,
        error_service: eventErrorService,
        alert_service_message: eventAlertService,
        alert_event: eventAlert,
        alert_type: eventAlertType,
      };

      window.dataLayer.push({ event: "alert", ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    },

    toggleDropdownPaidInstallments() {
      this.dropdownOpenPaidInstallments = !this.dropdownOpenPaidInstallments;
    },

    toggleDropdownOpenInstallments() {
      this.dropdownOpenInstallments = !this.dropdownOpenInstallments;

      if (!this.dropdownOpenInstallments) {
        const dropdownLink = document.getElementById("userDropdownOpen");
        if (dropdownLink) {
          dropdownLink.setAttribute("href", "#head1");
        }
      } else {
        const dropdownLink = document.getElementById("userDropdownOpen");
        if (dropdownLink) {
          dropdownLink.setAttribute("href", "#head3");
        }
      }
    },
  },
};
</script>
<style scoped>
html {
  scroll-behavior: smooth;
}
.flex-card {
  display: flex;
  justify-content: space-between;
}
.flex-align-center {
  align-items: center;
}
#userDropdownOpen::after {
  display: none !important;
}

</style>
